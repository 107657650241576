<template>
  <div>
    <a-row type="flex" :gutter="[16, 16]">
      <a-col
        v-for="(item, index) in appListData"
        :key="item.dbName"
        :xxl="6"
        :xl="6"
        :lg="12"
        :sm="12"
        :md="12"
        :xs="24"
        :order="4"
      >
        <a-spin :spinning="loading">
          <a-card hoverable style="width: 100%">
            <a-card-meta :title="item.packageName">
              <template #avatar>
                <a-avatar v-if="item.image" :src="item.image" />
                <a-avatar v-if="item.packageName === '今日数据'">
                  <template #icon>
                    <!-- <FieldTimeOutlined /> -->
                    <img src="@/assets/today.png" alt="" />
                  </template>
                </a-avatar>
                <a-avatar v-if="item.packageName === '昨日数据'">
                  <template #icon>
                    <!-- <ClockCircleOutlined /> -->
                    <img src="@/assets/yesday.png" alt="" />
                  </template>
                </a-avatar>
              </template>
            </a-card-meta>
            <div class="sort" v-if="index >= 2 && index <= 4">
              <img v-if="index-1 === 1" src="@/assets/one.png" alt="" />
              <img v-if="index-1 === 2" src="@/assets/two.png" alt="" />
              <img v-if="index-1 === 3" src="@/assets/three.png" alt="" />
              <!-- <div class="num">{{ index - 1 }}</div> -->
            </div>
            <p></p>
            <p>
              <span class="form-title">总安装</span
              ><span class="form-val">{{
                item.reg + item.newAndroidUser + item.newIosUser
                  ? item.reg + item.newAndroidUser + item.newIosUser
                  : 0
              }}</span>
            </p>
            <p>
              <span class="form-title">总注册</span
              ><span class="form-val">{{ item.reg ? item.reg : 0 }}</span>
            </p>
            <p>
              <span class="form-title">新增付费用户数</span
              ><span class="form-val">{{
                item.newPayUserNum ? item.newPayUserNum : 0
              }}</span>
            </p>
            <p class="form-amount">
              <span class="form-title">新增付费金额</span
              ><span class="form-val">
                <a-statistic
                  title=""
                  prefix="$"
                  :precision="2"
                  :value="`${
                    item.newPayUserSumAmount / 100
                      ? item.newPayUserSumAmount / 100
                      : 0
                  }`"
              /></span>
            </p>
            <p class="form-amount">
              <span class="form-title">付费总金额</span
              ><span class="form-val">
                <a-statistic
                  title=""
                  prefix="$"
                  :precision="2"
                  :value="`${
                    item.totalRevenue / 100 ? item.totalRevenue / 100 : 0
                  }`"
              /></span>
            </p>
          </a-card>
        </a-spin>
      </a-col>
    </a-row>
  </div>
</template>

<script lang="ts" setup>
import { FieldTimeOutlined, ClockCircleOutlined } from "@ant-design/icons-vue";
import { defineAsyncComponent, onMounted, reactive, ref, watch } from "vue";
import { sysDailyList, loginOut, resetPassword } from "@/api/api";
import dayjs, { Dayjs } from "dayjs";
import { ts_to_time } from "@/utils/utils";

interface AppObject {
  dbName: string;
  image: string;
  packageName: string;
  newPayUserSumAmount: number;
  newPayUserNum: number;
  rechargeTotalAmount: number;
  newAndroidUser: number;
  newIosUser: number;
  reg: number;
  totalRevenue: number;
}

const props = defineProps<{
  appData: any;
  dateTime: any;
  isConsume: boolean;
  isRefresh: boolean;
}>();
const appList = ref<Array<AppObject>>([]);
const dateValue = ref<Array<string>>([]);
const loading = ref(false);
const isXiaohao = ref(false);
const appListData = ref([] as Array<any>);
const appYesTodayListData = ref([] as Array<any>);
const allAppListData = ref([] as Array<any>);
const appListDataTemp = ref([] as Array<any>);

const appObject = ref<AppObject>({
  dbName: "",
  image: "",
  packageName: "",
  newPayUserSumAmount: 0,
  newPayUserNum: 0,
  rechargeTotalAmount: 0,
  reg: 0,
  newAndroidUser: 0,
  newIosUser: 0,
  totalRevenue: 0,
});
const count = ref(0);
const timer = ref();

onMounted(() => {
  appList.value = props.appData;
  dateValue.value = props.dateTime;
  setInterval(() => {
    traverseData();
    updateTime();
  }, 600000);
});

watch(
  () => props.appData,
  (cur, old) => {
    appList.value = cur;
    traverseData();
  }
);

watch(
  () => props.dateTime,
  (cur, old) => {
    dateValue.value = cur;
    traverseData();
  }
);

watch(
  () => props.isRefresh,
  (cur, old) => {
    // traverseData();
  }
);

watch(
  () => props.isConsume,
  (cur, old) => {
    isXiaohao.value = cur;
    // traverseData();
  }
);

const updateTime = () => {
  if (
    Number(new Date(dayjs().format("YYYY-MM-DD HH:mm:ss"))) >=
    Number(new Date(dayjs().format("YYYY-MM-DD") + " 23:40:00"))
  ) {
    timer.value = setInterval(() => {
      if (dayjs().format("HH:mm:ss") === "00:00:10") {
        clearInterval(timer.value);
        timer.value = null;
        location.reload();
      }
      if (
        Number(new Date(dayjs().format("YYYY-MM-DD HH:mm:ss"))) >=
        Number(new Date(dayjs().format("YYYY-MM-DD") + " 00:00:20"))
      ) {
        clearInterval(timer.value);
        timer.value = null;
      }
    }, 1000);
  } else {
    clearInterval(timer.value);
    timer.value = null;
  }
};

const traverseData = async () => {
  if (!appList.value.length || loading.value) {
    return;
  }
  count.value = 0;
  allAppListData.value = JSON.parse(JSON.stringify(appList.value));
  appYesTodayListData.value = [];

  // var searchMonth = dayjs(dateValue.value[0]).month() + 1;
  // var curMonth = new Date(ts_to_time(Number(new Date()))).getMonth() + 1;
  // if (searchMonth === curMonth) {

  await getAppDataYesToday(appObject.value);
  // }
  appList.value.forEach(async (val, index) => {
    await getAppData(val);
    if (appList.value.length === count.value) {
      sortHandle();
    }
  });
};
// 按今日付费总金额排序
const sortHandle = () => {
  var arr = allAppListData.value.sort((a, b) => {
    return b.totalRevenue - a.totalRevenue;
  });
  appListData.value = appYesTodayListData.value.concat(arr);
};
// 赋值数据
const assignmentHandle = (val: any, appData: any) => {
  var appObject = {
    dbName: appData.dbName,
    image: appData.image,
    packageName: val.packageName ? val.packageName : 0,
    newPayUserSumAmount: val.newPayUserSumAmount ? val.newPayUserSumAmount : 0,
    newPayUserNum: val.newPayUserNum ? val.newPayUserNum : 0,
    rechargeTotalAmount: val.rechargeTotalAmount ? val.rechargeTotalAmount : 0,
    reg: val.reg ? val.reg : 0,
    newAndroidUser: val.newAndroidUser ? val.newAndroidUser : 0,
    newIosUser: val.newIosUser ? val.newIosUser : 0,
    totalRevenue: val.totalRevenue ? val.totalRevenue : 0,
  };
  return appObject;
};
// // 修改数据
const updateAppData = (data: any, objectData: any) => {
  for (const key in objectData) {
    data[key] = objectData[key];
  }
  return data;
};
// 赋值修改后的数据
var comparedApplist = (appData: any, data: any) => {
  allAppListData.value.forEach((val) => {
    if (val.dbName === appData.dbName) {
      val = updateAppData(val, assignmentHandle(data, appData));
    }
  });
};
// 赋值修改后今日昨日的数据
var comparedYesTodayApplist = (appData: any, data: any) => {
  appYesTodayListData.value.forEach((val) => {
    if (val.dbName === appData.dbName) {
      val = updateAppData(val, assignmentHandle(data, appData));
    }
  });
};
const getAppDataYesToday = (appData: AppObject) => {
  return new Promise<Array<AppObject>>((resolve, reject) => {
    var timeNum = Number(new Date()) - 3600 * 24 * 1000;
    const params = {
      dbName: appData.dbName,
      pageNo: 1,
      pageSize: 3,
      beginTime: dayjs(ts_to_time(timeNum)).format("YYYY-MM-DD"),
      endTime: dayjs().format("YYYY-MM-DD"),
      isUse: 0,
    };
    loading.value = true;
    sysDailyList(params).then((res) => {
      loading.value = false;
      if (res.code === "200") {
        var paramsArr = res.data.records;
        if (paramsArr[0]) {
          if (!appData.dbName) {
            var data2: any = paramsArr[2];
            if (!data2.packageName) {
              data2.packageName = "昨日数据";
              appYesTodayListData.value.unshift(
                assignmentHandle(data2, appData)
              );
            } else {
              comparedYesTodayApplist(appData, data2);
            }

            var data1: any = paramsArr[1];
            if (!data1.packageName) {
              data1.packageName = "今日数据";
              appYesTodayListData.value.unshift(
                assignmentHandle(data1, appData)
              );
            } else {
              comparedYesTodayApplist(appData, data1);
            }
          }
        }
      }
      resolve(appYesTodayListData.value);
    });
  });
};

const getAppData = (appData: AppObject) => {
  return new Promise<any[]>((resolve, reject) => {
    const params = {
      dbName: appData.dbName,
      pageNo: 1,
      pageSize: 3,
      beginTime: dayjs(dateValue.value[0]).format("YYYY-MM-DD"),
      endTime: dayjs(dateValue.value[1]).format("YYYY-MM-DD"),
      isUse: 0,
    };
    loading.value = true;
    sysDailyList(params).then((res) => {
      count.value++;
      loading.value = false;
      if (res.code === "200") {
        var paramsArr = res.data.records;
        if (paramsArr[0]) {
          if (appData.dbName) {
            var data1: any = paramsArr[0];
            data1.packageName = appData.packageName;
            comparedApplist(appData, data1);
          }
        } else {
          var data1: any = {};
          data1.packageName = appData.packageName;
          comparedApplist(appData, data1);
        }
      }
      resolve(allAppListData.value);
    });
  });
};
</script>

<style scoped lang="less">
.form-title {
  margin-right: 10px;
}
.form-val {
  font-size: 16px !important;
  font-weight: 600;
}
.form-amount {
  display: flex;
  align-items: center;
}
.sort {
  position: absolute;
  top: 0;
  right: 10px;
  display: flex;
  width: 50px;
  height: 50px;
  img {
  }
  .num {
    color: white;
    font-size: 20px;
    font-weight: 600;
    display: flex;
    position: absolute;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
}
</style>
<style>
.ant-statistic-content {
  font-size: 16px !important;
}
</style>
