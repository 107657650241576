<template>
  <div :class="modeChangeKey === 'card' ? 'nav' : 'nav2'">
    <!-- :style="{ height: modeChangeKey === 'card'? '60px !important' : '90px !important'}" -->
    <div>
      <a-range-picker
        v-if="modeChangeKey === 'card' || modeChangeKey === 'table'"
        style="margin-right: 10px"
        format="YYYY-MM-DD"
        v-model:value="dateValue"
        :ranges="rangePresets"
        @change="onRangeChange"
      />
      <!-- 
        v-if="modeChangeKey === 'table' && isCardMode" -->
      <a-button
        class="all"
        type="primary"
        @click="xiaohaoClick"
        :disabled="loading"
        v-if="modeChangeKey === 'table' && isCardMode"
        >{{ isXiaohao ? "全部" : "消耗" }}
        <template #icon><SwapOutlined /></template>
      </a-button>
      <a-button
        type="primary"
        class="refresh"
        @click="reload"
        :disabled="loading"
      >
        <template #icon><RedoOutlined /></template>
      </a-button>
    </div>
    <div class="header">
      <!-- <div style="margin-right:3px;font-weight: 600;margin-top:5px">
        {{ isCardMode?"管理员":"用户" }}
      </div> -->
      <a-dropdown :trigger="['click']" class="all">
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="editPassword"> 修改密码 </a-menu-item>
            <a-menu-item key="2" @click="loginOutClick"> 退出登录 </a-menu-item>
          </a-menu>
        </template>
        <a-button>
          <template #icon><MenuOutlined /></template>
        </a-button>
      </a-dropdown>
    </div>
  </div>

  <div class="home-content">
    <div>
      <a-tabs
        v-model:activeKey="modeChangeKey"
        type="card"
        @change="modeChangeClick"
      >
        <a-tab-pane key="card" tab="汇总显示">
          <all-app
            :appData="configList"
            :dateTime="dateValue"
            :is-consume="isXiaohao"
            :loading="loading"
            :isRefresh="isRefresh"
          ></all-app>
        </a-tab-pane>
        <!-- v-if="isCardMode" -->
        <a-tab-pane key="table" tab="列表显示" v-if="isCardMode">
          <a-tabs v-model:activeKey="activeKey" @tabClick="tabClick">
            <a-tab-pane :key="-1" tab="全部"> </a-tab-pane>
            <a-tab-pane
              v-for="(item, index) in configList"
              :key="index"
              :tab="item.packageName"
              :disabled="loading"
            >
            </a-tab-pane>
          </a-tabs>
          <XATable
            :columns="columns"
            :dataSource="tableData"
            :scroll="{ x: 1500 }"
            :loading="loading"
          >
            <!-- <template #count="{ scope }">
        {{
          Number(scope.record.newAndroidUser) +
          Number(scope.record.newIosUser) +
          Number(scope.record.reg)
        }}
      </template> -->
          </XATable>
          <a-pagination
            v-model:current="pageNo"
            v-model:pageSize="pageSize"
            @change="pageNoChange"
            :total="total"
            show-size-changer
            @showSizeChange="onShowSizeChange"
            style="margin-top: 5px"
          />
        </a-tab-pane>
        <a-tab-pane key="statistics" tab="监控统计" class="a-tab-pane-content">
          <statistics
            :dbName="appIdVal"
            :is-refresh="isRefresh"
            :appData="configList"
          ></statistics>
        </a-tab-pane>
        <a-tab-pane
          key="subscription"
          tab="订阅监测"
          class="a-tab-pane-content"
        >
          <subscription
            :dbName="appIdVal"
            :is-refresh="isRefresh"
            :appData="configList"
          ></subscription>
        </a-tab-pane>
        <a-tab-pane key="back" tab="退款监听" class="a-tab-pane-content">
          <back
            :dbName="appIdVal"
            :is-refresh="isRefresh"
            :appData="configList"
          ></back>
        </a-tab-pane>
      </a-tabs>
    </div>
    <!-- <div v-else>
      <all-app
        :appData="configList"
        :dateTime="dateValue"
        :is-consume="isXiaohao"
        :loading="loading"
        :isRefresh="isRefresh"
      ></all-app>
    </div> -->
  </div>
  <x-dialog
    title="修改密码"
    :visible="dialogVisible"
    @confirm="confirm"
    @cancel="dialogVisible = false"
  >
    <a-form
      :model="formState"
      name="basic"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      autocomplete="off"
    >
      <a-form-item
        label="用户名"
        name="account"
        :rules="[{ required: false, message: '请输入用户名!' }]"
      >
        <a-input disabled v-model:value="formState.account" />
      </a-form-item>
      <a-form-item
        label="旧密码"
        name="oldPassword"
        :rules="[{ required: true, message: '请输入旧密码!' }]"
      >
        <a-input-password v-model:value="formState.oldPassword" />
      </a-form-item>

      <a-form-item
        label="新密码"
        name="password"
        :rules="[{ required: true, message: '请输入新密码!' }]"
      >
        <a-input-password v-model:value="formState.password" />
      </a-form-item>

      <!-- <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
        <a-button type="primary" html-type="submit">Submit</a-button>
      </a-form-item> -->
    </a-form>
  </x-dialog>
</template>

<script lang="ts" setup>
import {
  SwapOutlined,
  MenuOutlined,
  RedoOutlined,
} from "@ant-design/icons-vue";
import XDialog from "@/components/XDialog.vue";
import AllApp from "@/views/AllApp.vue";
import {
  defineAsyncComponent,
  onBeforeMount,
  onBeforeUnmount,
  onMounted,
  reactive,
  ref,
} from "vue";
import dayjs, { Dayjs } from "dayjs";
import Statistics from "@/views/statistics.vue";
import Subscription from "@/views/Subscription.vue";
import Back from "@/views/Back.vue";
import {
  sysConfigList,
  sysDailyList,
  loginOut,
  resetPassword,
} from "@/api/api";
import { removeToken } from "@/utils/auth";
import { useRouter } from "vue-router";
import { ts_to_time } from "@/utils/utils";
const monthDay = () => {
  return 3600 * 24 * 1000 * 30;
};
const XATable = defineAsyncComponent(() => import("../components/XATable.vue"));
type RangeValue = [Dayjs, Dayjs];
const activeName = ref("first");
const tableData = ref([] as any[]);
const activeKey = ref(-1);
const configList = ref([] as any[]);
const pageNo = ref(1);
const pageSize = ref(50);
const loading = ref(false);
const total = ref(0);
const dbName = ref("");
const dateValue = ref([dayjs().startOf("month"), dayjs()]);
const appIdVal = ref();

const monthString1 = String(
  new Date(ts_to_time(Number(new Date()))).getMonth() + 1
) as any;
const monthString2 = String(
  new Date(ts_to_time(Number(new Date()) - monthDay())).getMonth() + 1
) as any;
const monthString3 = String(
  new Date(ts_to_time(Number(new Date()) - monthDay() * 2)).getMonth() + 1
) as any;

const isXiaohao = ref(true);
const dialogVisible = ref();
const router = useRouter();
const modeChangeKey = ref();
const isCardMode = ref(localStorage.getItem("mode") === "admin");

interface FormState {
  account: string;
  password: string;
  oldPassword: string;
}

const formState = ref<FormState>({
  account: localStorage.getItem("mode") === "admin" ? "admin" : "user",
  password: "",
  oldPassword: "",
});
const isRefresh = ref(false);
var rangePresets = reactive({} as any);

const reload = () => {
  // isRefresh.value = !isRefresh.value
  getConfigList();
};

const assignmentDate = (mode: string) => {
  if (mode === "card") {
    dateValue.value = [dayjs(), dayjs()];
    getConfigList();
  } else if (mode === "table") {
    dateValue.value = [dayjs().startOf("month"), dayjs()];
    getConfigList();
  } else {
  }
};

const modeChangeClick = (mode: string) => {
  assignmentDate(mode);
  localStorage.setItem("MODE", mode);
};
const editPassword = () => {
  dialogVisible.value = true;
};
const loginOutClick = () => {
  loginOut({}).then((res) => {
    router.replace("/");
    removeToken();
  });
};
const confirm = () => {
  resetPassword(formState.value).then((res) => {
    if (res.code === "200") {
      loginOutClick();
    }
  });
};
const onFinishFailed = () => {};
onBeforeMount(() => {
  if (localStorage.getItem("MODE")) {
    modeChangeKey.value = localStorage.getItem("MODE") as string;
    // 判断是否是user
    if (!isCardMode.value) {
      if (localStorage.getItem("MODE") === "table") {
        modeChangeKey.value = "card";
      }
    }
  } else {
    modeChangeKey.value = "card";
  }
});
onMounted(() => {
  assignmentDate(modeChangeKey.value);
  rangePresets[monthString1 + "月"] = [
    dayjs()
      .month(monthString1 - 1)
      .startOf("month"),
    dayjs()
      .month(monthString1 - 1)
      .endOf("month"),
  ] as RangeValue;

  rangePresets[monthString2 + "月"] = [
    dayjs()
      .month(monthString1 - 2)
      .startOf("month"),
    dayjs()
      .month(monthString1 - 2)
      .endOf("month"),
  ] as RangeValue;

  rangePresets[monthString3 + "月"] = [
    dayjs()
      .month(monthString1 - 3)
      .startOf("month"),
    dayjs()
      .month(monthString1 - 3)
      .endOf("month"),
  ] as RangeValue;

  getConfigList();
});

const getConfigList = () => {
  loading.value = true;
  sysConfigList({
    isUse: isXiaohao.value ? 0 : 1,
  }).then((res: any) => {
    // console.log(res);
    configList.value = res.data;
    getDatas(dbName.value);
  });
};
const getDatas = (dbName: String = "") => {
  const params = {
    dbName: dbName,
    pageNo: pageNo.value,
    pageSize: pageSize.value,
    beginTime: dayjs(dateValue.value[0]).format("YYYY-MM-DD"),
    endTime: dayjs(dateValue.value[1]).format("YYYY-MM-DD"),
    isUse: isXiaohao.value ? 0 : 1,
  };
  loading.value = true;
  sysDailyList(params).then((res) => {
    loading.value = false;
    total.value = res.data.total;
    if (res.code === "200") {
      var data: any[] = [];
      res.data.records.forEach((element: { day: string }) => {
        if (!element.day) {
          element.day = "汇总";
        }
        data.push(element);
      });
      tableData.value = data;
    }
  });
};
const xiaohaoClick = () => {
  isXiaohao.value = !isXiaohao.value;
  isXiaohao.value
    ? (columns.value = columns1.value)
    : (columns.value = columns2.value);
  getConfigList();
};
const tabClick = (tabIndex: any) => {
  if (tabIndex === -1) {
    dbName.value = "";
  } else {
    const item = configList.value[tabIndex];
    dbName.value = item.dbName;
  }
  getDatas(dbName.value);
};
const pageNoChange = (val: number) => {
  pageNo.value = val;
  getDatas(dbName.value);
};
const onShowSizeChange = (current: any, size: number) => {
  pageSize.value = size;
  getDatas(dbName.value);
};
const onRangeChange = (dates: RangeValue, dateStrings: string[]) => {
  getDatas(dbName.value);
};
const columns1 = ref([
  // {
  //   title: "序号",
  //   dataIndex: "index",
  //   key: "index",
  //   align: "center",
  //   customRender: (column: { index: number }) => {
  //     return (pageNo.value - 1) * pageSize.value + column.index + 1;
  //     // return column.index + 1;
  //   },
  //   width: 60,
  // },
  {
    title: "日期",
    key: "day",
    dataIndex: "day",
    width: 120,
  },
  // {
  //   title: "新增用户数",
  //   key: "count",
  //   dataIndex: "count",
  //   slots: {
  //     customRender: "count",
  //   },
  // },
  {
    title: "新增注册人数",
    key: "reg",
    dataIndex: "reg",
  },
  {
    title: "充值用户数",
    key: "totalRecharge",
    dataIndex: "totalRecharge",
  },
  {
    title: "充值成功用户数",
    key: "successRecharge",
    dataIndex: "successRecharge",
  },
  {
    title: "充值总金额",
    key: "rechargeTotalAmount",
    dataIndex: "rechargeTotalAmount",
    isMinute: true,
    symbol: "$",
  },
  {
    title: "开通VIP数",
    key: "totalVipNum",
    dataIndex: "totalVipNum",
  },
  {
    title: "开通VIP成功数",
    key: "successVipNum",
    dataIndex: "successVipNum",
  },
  {
    title: "开通VIP总金额",
    key: "vipTotalAmount",
    dataIndex: "vipTotalAmount",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "开通SVIP数",
    key: "totalSvipNum",
    dataIndex: "totalSvipNum",
  },
  {
    title: "开通SVIP成功数",
    key: "successSvipNum",
    dataIndex: "successSvipNum",
  },
  {
    title: "开通SVIP总金额",
    key: "svipTotalAmount",
    dataIndex: "svipTotalAmount",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "新增付费用户数",
    key: "newPayUserNum",
    dataIndex: "newPayUserNum",
  },
  {
    title: "新增付费用户金额",
    key: "newPayUserSumAmount",
    dataIndex: "newPayUserSumAmount",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "退款金额",
    key: "refundAmount",
    dataIndex: "refundAmount",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "总收入",
    key: "totalRevenue",
    dataIndex: "totalRevenue",
    symbol: "$",
    isMinute: true,
  },
]);

const columns2 = ref([
  // {
  //   title: "序号",
  //   dataIndex: "index",
  //   key: "index",
  //   align: "center",
  //   customRender: (column: { index: number }) => {
  //     return (pageNo.value - 1) * pageSize.value + column.index + 1;
  //     // return column.index + 1;
  //   },
  //   width: 60,
  // },
  {
    title: "日期",
    key: "day",
    dataIndex: "day",
    width: 120,
  },
  // {
  //   title: "新增用户数",
  //   key: "count",
  //   dataIndex: "count",
  //   slots: {
  //     customRender: "count",
  //   },
  // },
  {
    title: "新增注册人数",
    key: "reg",
    dataIndex: "reg",
  },
  {
    title: "充值用户数",
    key: "totalRecharge",
    dataIndex: "totalRecharge",
  },
  {
    title: "充值成功用户数",
    key: "successRecharge",
    dataIndex: "successRecharge",
  },
  {
    title: "充值总金额",
    key: "rechargeTotalAmount",
    dataIndex: "rechargeTotalAmount",
    isMinute: true,
    symbol: "$",
  },
  {
    title: "开通VIP数",
    key: "totalVipNum",
    dataIndex: "totalVipNum",
  },
  {
    title: "开通VIP成功数",
    key: "successVipNum",
    dataIndex: "successVipNum",
  },
  {
    title: "开通VIP总金额",
    key: "vipTotalAmount",
    dataIndex: "vipTotalAmount",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "开通SVIP数",
    key: "totalSvipNum",
    dataIndex: "totalSvipNum",
  },
  {
    title: "开通SVIP成功数",
    key: "successSvipNum",
    dataIndex: "successSvipNum",
  },
  {
    title: "开通SVIP总金额",
    key: "svipTotalAmount",
    dataIndex: "svipTotalAmount",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "新增付费用户数",
    key: "newPayUserNum",
    dataIndex: "newPayUserNum",
  },
  {
    title: "新增付费用户金额",
    key: "newPayUserSumAmount",
    dataIndex: "newPayUserSumAmount",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "国内户消耗",
    key: "cnUse",
    dataIndex: "cnUse",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "FB销量消耗",
    key: "fbUse",
    dataIndex: "fbUse",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "FB新增消耗",
    key: "fbNewUse",
    dataIndex: "fbNewUse",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "TikTok消耗",
    key: "ttUse",
    dataIndex: "ttUse",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "海外户消耗",
    key: "otherUse",
    dataIndex: "otherUse",
    symbol: "₦",
    isMinute: true,
  },
  {
    title: "退款金额",
    key: "refundAmount",
    dataIndex: "refundAmount",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "盈利",
    key: "dayProfit",
    dataIndex: "dayProfit",
    symbol: "$",
    isMinute: true,
  },
  {
    title: "总收入",
    key: "totalRevenue",
    dataIndex: "totalRevenue",
    symbol: "$",
    isMinute: true,
  },
]);

const columns = ref(columns1.value);
</script>

<style scoped lang="less">
.home-content {
  padding: 10px;
}
.nav {
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.nav2 {
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.all {
  margin-top: 0px;
}
.edit-password {
  /* margin-top: 5px; */
}
.refresh {
  padding-top: 0px;
  margin-left: 5px;
}
.header {
  display: flex;
  align-items: center;
}
.a-tab-pane-content {
  overflow-x: auto !important;
}

@media screen and (max-width: 430px) {
  .edit-password {
    margin-top: 0px;
  }
  .refresh {
    margin-left: 0px;
    margin-top: 5px;
  }
  .all {
    margin-top: 5px !important;
  }
  .nav {
    height: 60px !important;
    align-items: normal !important;
  }

  .nav2 {
    height: 90px !important;
    align-items: normal !important;
  }
}
</style>
