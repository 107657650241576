import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Login from '@/views/Login.vue'
import HomeView from '@/views/DataCenter.vue'
import Accounts from '@/views/Accounts.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/login',
    children: [
      {
        path: '/login',
        component: Login
      },
    ]
  },
  {
    path: '/home',
    name: 'home',
    component: HomeView,
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: Accounts,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
