<template>
  <div
    style="display: flex; justify-content: space-between; align-items: center"
  >
    <a-select
      ref="select"
      v-model:value="rightAppIdVal"
      mode="multiple"
      @change="rightAppIdValChange"
      :max-tag-count="maxTagCount"
      style="min-width: 120px; margin-bottom: 10px"
    >
      <a-select-option
        v-for="item in appData"
        :key="item.dbName"
        :value="item.dbName"
        >{{ item.packageName }}</a-select-option
      >
    </a-select>
    <div class="from-item">
      弹窗提醒
      <a-switch
        style="margin-left: 10px"
        v-model:checked="checkedValue"
        @change="notifChange"
      ></a-switch>
    </div>
  </div>
  <XATable
    :columns="columns"
    :dataSource="tableData"
    :scroll="{ x: 1500 }"
    :loading="loading"
  >
    <template #type="{ scope }">
      {{
        scope.record.type === 1
          ? "充值"
          : scope.record.type === 2
          ? "VIP"
          : scope.record.type === 3
          ? "SVIP"
          : ""
      }}
    </template>
    <template #userAmountTotal="{ scope }">
      {{ "$" + (scope.record.userAmountTotal / 100).toFixed(2) }}
    </template>
    <template #amount="{ scope }">
      {{ "$" + (scope.record.amount / 100).toFixed(2) }}
    </template>
  </XATable>
</template>

<script lang="ts" setup>
import Vue, {
  onMounted,
  ref,
  watch,
  defineAsyncComponent,
  onBeforeUnmount,
  computed,
} from "vue";
import { orderSubCancelList, orderSubCancel } from "@/api/api";
const XATable = defineAsyncComponent(() => import("../components/XATable.vue"));
import { notification } from "ant-design-vue";
import { onBeforeRouteLeave } from "vue-router";
import { useStore } from "vuex";

const store = useStore();

const props = defineProps<{
  appData: any;
  //   dateTime: any;
  //   isConsume: boolean;
  isRefresh: boolean;
  dbName: String;
}>();
const rightAppIdVal = ref<any[]>([]);
var appIdList: any[] = [];
const maxTagCount = ref(1);
const checkedValue = ref(true);

const columns = ref([
  {
    title: "包名",
    key: "packageName",
    dataIndex: "packageName",
  },
  {
    title: "用户ID",
    key: "userId",
    dataIndex: "userId",
  },
  {
    title: "用户名",
    key: "userName",
    dataIndex: "userName",
  },
  {
    title: "订单类型",
    key: "type",
    dataIndex: "type",
    slots: {
      customRender: "type",
    },
  },
  {
    title: "订单编号",
    key: "tradeNo",
    dataIndex: "tradeNo",
  },
  {
    title: "地区",
    key: "ipName",
    dataIndex: "ipName",
  },
  {
    title: "订阅价格",
    key: "amount",
    dataIndex: "amount",
    slots: {
      customRender: "amount",
    },
  },
  {
    title: "订阅次数",
    key: "subPayTotal",
    dataIndex: "subPayTotal",
  },
  {
    title: "订阅天数",
    key: "subDayTotal",
    dataIndex: "subDayTotal",
  },
  {
    title: "总充值",
    key: "userAmountTotal",
    dataIndex: "userAmountTotal",
    slots: {
      customRender: "userAmountTotal",
    },
  },
  {
    title: "充值次数",
    key: "userPayTotal",
    dataIndex: "userPayTotal",
  },
  {
    title: "下单时间",
    key: "orderTime",
    dataIndex: "orderTime",
    isAllTime: true,
  },
  {
    title: "取消时间",
    key: "cancelTime",
    dataIndex: "cancelTime",
    isAllTime: true,
  },
]);
const tableData = ref([]);
const loading = ref(false);
let timerInval = ref();
var subOrderIds: any[] = [];

const getRequestData = () => {
  loading.value = true;
  orderSubCancelList({
    dbName: `${decodeURIComponent(rightAppIdVal.value.join(","))}`,
  }).then((res) => {
    loading.value = false;
    if (res.data.length) {
      tableData.value = res.data;
    }
  });
};

const startSubTimer = () => {
  clearInterval(timerInval.value);
  timerInval.value = null;
  timerInval.value = setInterval(() => {
    suborderSubCancel();
  }, 2000);
};
// 监听 Vuex 的 refreshNeeded 状态
const refreshNeeded = computed(() => store.state.refreshNeeded);

// 监控 refreshNeeded 的变化
watch(refreshNeeded, (newValue) => {
  if (newValue) {
    // 执行页面刷新操作
    getRequestData();
    // 重置刷新状态
    store.dispatch("resetRefresh");
  }
});

watch(
  () => props.isRefresh,
  (cur, old) => {
    if (rightAppIdVal.value.length) {
      getRequestData();
      // startSubTimer();
    }
  }
);

watch(
  () => store.state.configList,
  (cur, old) => {
    rightAppIdVal.value = cur;
  },
  { immediate: true }
);
// watch(
//   () => props.appData,
//   (cur, old) => {
//     if (cur) {
//       appIdList = [];
//       cur.forEach((val: { dbName: any }) => {
//         appIdList.push(val.dbName);
//       });
//       if (localStorage.getItem("SUBAPPIDLIST")) {
//         rightAppIdVal.value = JSON.parse(
//           localStorage.getItem("SUBAPPIDLIST") as string
//         );
//       } else {
//         rightAppIdVal.value = appIdList;
//       }
//       if (rightAppIdVal.value.length) {
//         getRequestData();
//         startSubTimer();
//       }
//     }
//   },
//   { immediate: true, deep: true }
// );

// const suborderSubCancel = () => {
//   orderSubCancel({
//     dbName: `${decodeURIComponent(rightAppIdVal.value.join(","))}`,
//   }).then((res) => {
//     if (res.data && res.data.length) {
//       let data = res.data[0];
//       subOrderIds = JSON.parse(localStorage.getItem("SubOrderIds") as string)
//         ? JSON.parse(localStorage.getItem("SubOrderIds") as string)
//         : [];
//       if (!subOrderIds.includes(data.tradeNo)) {
//         //弹出窗口
//         if (checkedValue.value) {
//           notification["warning"]({
//             message: "取消订阅通知",
//             description: `${data.packageName}-${data.userName} 用户取消订阅`,
//           });
//         }
//         getRequestData();
//         subOrderIds.push(data.tradeNo);
//         localStorage.setItem("SubOrderIds", JSON.stringify(subOrderIds));
//       }
//     }
//   });
// };

const notifChange = (val: boolean) => {
  store.state.dispatch("changeSubCheckedValue");
};

const rightAppIdValChange = (val: any[]) => {
  store.dispatch("changeSubConfigList", val);
  if (val.length) {
    getRequestData();
  }
};
onMounted(() => {
  // let val = localStorage.getItem("NotifNO");
  // if (val) {
  //   checkedValue.value = val === "true" ? true : false;
  // }
  checkedValue.value = store.state.subCheckedValue;
  getRequestData();
});
onBeforeUnmount(() => {
  // clearInterval(timerInval.value);
  // timerInval.value = null;
});
</script>
