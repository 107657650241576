<template>
  <div class="content">
    <div class="data-top">
      <a-row :gutter="30" class="a-row-content">
        <a-col :span="12">
          <div class="">
            <a-row>
              <a-col :span="16">
                <a-divider orientation="left">订单汇总</a-divider></a-col
              >
              <a-col :span="8">
                <div class="right-select">
                  <a-select
                    ref="select"
                    v-model:value="appIdVal"
                    @change="appIdChange"
                    style="width: 120px"
                  >
                    <a-select-option
                      v-for="item in appData"
                      :key="item.dbName"
                      :value="item.dbName"
                      >{{ item.packageName }}</a-select-option
                    >
                  </a-select>
                </div>
              </a-col>
            </a-row>
            <div>
              <a-row :gutter="16">
                <a-col :span="8">
                  <a-spin :spinning="orderAllLoading">
                    <a-card title="普通充值" :bordered="false" size="small">
                      <a-statistic
                        :value="
                          normalData.top.amount === null
                            ? 0
                            : (normalData.top.amount / 100).toFixed(2)
                        "
                      />
                    </a-card>
                  </a-spin>
                </a-col>
                <a-col :span="8">
                  <a-spin :spinning="orderAllLoading">
                    <a-card title="VIP会员" :bordered="false" size="small">
                      <a-statistic
                        :value="
                          vipData.top.amount === null
                            ? 0
                            : (vipData.top.amount / 100).toFixed(2)
                        "
                      />
                    </a-card>
                  </a-spin>
                </a-col>
                <a-col :span="8">
                  <a-spin :spinning="orderAllLoading">
                    <a-card title="SVIP会员" :bordered="false" size="small">
                      <a-statistic
                        :value="
                          svipData.top.amount === null
                            ? 0
                            : (svipData.top.amount / 100).toFixed(2)
                        "
                      />
                    </a-card>
                  </a-spin>
                </a-col>
              </a-row>
            </div>

            <div style="margin-top: 20px">
              <a-row :gutter="16">
                <a-col :span="8">
                  <a-spin :spinning="orderAllLoading">
                    <a-card title="完成情况" :bordered="false" size="small">
                      <div class="finish-info">
                        <div>
                          {{
                            !normalData.top.payNum ? 0 : normalData.top.payNum
                          }}
                        </div>
                        <a-divider type="vertical" />
                        <div>
                          {{
                            !normalData.top.unPayNum
                              ? 0
                              : normalData.top.unPayNum
                          }}
                        </div>
                        <a-divider type="vertical" />
                        <div>
                          {{
                            !normalData.top.successPer
                              ? 0
                              : normalData.top.successPer
                          }}%
                        </div>
                      </div>
                    </a-card>
                  </a-spin>
                </a-col>
                <a-col :span="8">
                  <a-spin :spinning="orderAllLoading">
                    <a-card title="完成情况" :bordered="false" size="small">
                      <div class="finish-info">
                        <div>
                          {{ !vipData.top.payNum ? 0 : vipData.top.payNum }}
                        </div>
                        <a-divider type="vertical" />
                        <div>
                          {{ !vipData.top.unPayNum ? 0 : vipData.top.unPayNum }}
                        </div>
                        <a-divider type="vertical" />
                        <div>
                          {{
                            !vipData.top.successPer
                              ? 0
                              : vipData.top.successPer
                          }}%
                        </div>
                      </div>
                    </a-card>
                  </a-spin>
                </a-col>
                <a-col :span="8">
                  <a-spin :spinning="orderAllLoading">
                    <a-card title="完成情况" :bordered="false" size="small">
                      <div class="finish-info">
                        <div>
                          {{ !svipData.top.payNum ? 0 : svipData.top.payNum }}
                        </div>
                        <a-divider type="vertical" />
                        <div>
                          {{
                            !svipData.top.unPayNum ? 0 : svipData.top.unPayNum
                          }}
                        </div>
                        <a-divider type="vertical" />
                        <div>
                          {{
                            !svipData.top.successPer
                              ? 0
                              : svipData.top.successPer
                          }}%
                        </div>
                      </div>
                    </a-card>
                  </a-spin>
                </a-col>
              </a-row>
            </div>
            <div class="data-top-bottom">
              <a-row :gutter="16">
                <a-col :span="10">
                  <XATable
                    :columns="normalColumns"
                    :dataSource="normalData.bottom"
                    :scroll="{ y: 160 }"
                    size="small"
                    height="200px"
                    :loading="orderAllLoading"
                  >
                    <template #amount="{ scope }">
                      {{ (scope.record.amount / 100).toFixed(2) }}
                    </template>
                    <template #orders="{ scope }">
                      {{
                        scope.record.currentOrder + " / " + scope.record.orders
                      }}
                    </template>
                  </XATable>
                </a-col>
                <a-col :span="7">
                  <XATable
                    :columns="vipColumns"
                    :dataSource="vipData.bottom"
                    :scroll="{ y: 160 }"
                    size="small"
                    height="200px"
                    :loading="orderAllLoading"
                  >
                    <template #amount="{ scope }">
                      {{ (scope.record.amount / 100).toFixed(2) }}
                    </template>
                    <template #orders="{ scope }">
                      {{
                        scope.record.currentOrder + " / " + scope.record.orders
                      }}
                    </template>
                    <template #price="{ scope }">
                      {{ (scope.record.price / 100).toFixed(2) }}
                    </template>
                  </XATable>
                </a-col>
                <a-col :span="7">
                  <XATable
                    :columns="svipColumns"
                    :dataSource="svipData.bottom"
                    :scroll="{ y: 160 }"
                    size="small"
                    height="200px"
                    :loading="orderAllLoading"
                  >
                    <template #amount="{ scope }">
                      {{ (scope.record.amount / 100).toFixed(2) }}
                    </template>
                    <template #orders="{ scope }">
                      {{
                        scope.record.currentOrder + " / " + scope.record.orders
                      }}
                    </template>
                  </XATable>
                </a-col>
              </a-row>
            </div>
          </div>
        </a-col>
        <a-col :span="12">
          <div class="">
            <a-row>
              <a-col :span="16">
                <a-divider orientation="left">最新订单</a-divider></a-col
              >
              <a-col :span="8">
                <div class="right-select">
                  <a-select
                    ref="select"
                    v-model:value="rightAppIdVal"
                    mode="multiple"
                    @change="rightAppIdValChange"
                    :max-tag-count="maxTagCount"
                    style="min-width: 120px"
                  >
                    <a-select-option
                      v-for="item in appData"
                      :key="item.dbName"
                      :value="item.dbName"
                      >{{ item.packageName }}</a-select-option
                    >
                  </a-select>
                </div>
              </a-col>
            </a-row>
            <a-descriptions
              title="用户信息"
              :column="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 4, xs: 4 }"
            >
              <a-descriptions-item label="用户名">{{
                newUserInfo.userName
              }}</a-descriptions-item>
              <a-descriptions-item label="注册时间">{{
                newUserInfo.regTime
                  ? ts_to_time(newUserInfo.regTime * 1000)
                  : ""
              }}</a-descriptions-item>
              <a-descriptions-item label="地区">{{
                newUserInfo.ipName
              }}</a-descriptions-item>
              <a-descriptions-item label="总值">{{
                (newUserInfo.userPayTotal ? newUserInfo.userPayTotal : "") +
                (newUserInfo.userAmountTotal
                  ? " | " + (newUserInfo.userAmountTotal / 100).toFixed(2)
                  : "")
              }}</a-descriptions-item>
            </a-descriptions>
            <a-row :gutter="10" style="margin-top: 10px">
              <a-col :span="12">
                <a-descriptions
                  title="订单信息"
                  :column="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 4, xs: 4 }"
                >
                  <a-descriptions-item label="">
                    <div style="display: flex; flex-direction: column">
                      平台：{{ newUserInfo.packageName }}<br />
                      时间：{{
                        newUserInfo.orderTime
                          ? ts_to_time(newUserInfo.orderTime * 1000)
                          : ""
                      }}<br />
                      <div>
                        金额：<text style="color: red; font-weight: 500"
                          >{{
                            newUserInfo.amount
                              ? "$" + (newUserInfo.amount / 100).toFixed(2)
                              : ""
                          }}
                          {{
                            newUserInfo.moduleType
                              ? newUserInfo.moduleType === 0
                                ? " | 小说"
                                : newUserInfo.moduleType === 1
                                ? " | 短剧"
                                : ""
                              : ""
                          }}{{
                            newUserInfo.goodsName
                              ? " | " + newUserInfo.goodsName
                              : ""
                          }}</text
                        >
                      </div>
                      剧名：{{ newUserInfo.name }}<br />
                    </div>
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>
              <a-col :span="12">
                <a-descriptions
                  title="监听配置"
                  :column="{ xxl: 4, xl: 4, lg: 4, md: 4, sm: 4, xs: 4 }"
                >
                  <a-descriptions-item label="">
                    <div style="display: flex; flex-direction: column">
                      <div class="from-item">
                        语音播报：
                        <a-switch
                          style="margin-left: 10px"
                          v-model:checked="checkedValue"
                          @change="voiceChange"
                        ></a-switch>
                      </div>
                      <div>
                        监听状态：<span
                          :style="{
                            color: rightAppIdVal.length > 0 ? 'green' : 'red',
                          }"
                          >{{
                            rightAppIdVal.length > 0
                              ? "监听中..."
                              : "已停止监听"
                          }}</span
                        >
                      </div>
                    </div>
                  </a-descriptions-item>
                </a-descriptions>
              </a-col>
            </a-row>
            <div>
              <XATable
                :columns="newOrderColumns"
                :dataSource="newUserInfos"
                :scroll="{ y: 160 }"
                size="small"
                height="200px"
              >
                <template #amount="{ scope }">
                  {{
                    scope.record.type === 1
                      ? "充值 | "
                      : scope.record.type === 2
                      ? "VIP | "
                      : scope.record.type === 3
                      ? "SVIP | "
                      : ""
                  }}${{
                    scope.record.amount
                      ? (scope.record.amount / 100).toFixed(2)
                      : 0
                  }}
                </template>
                <template #userAmountTotal="{ scope }">
                  {{ scope.record.userPayTotal }} |
                  {{ "$" + (scope.record.userAmountTotal / 100).toFixed(2) }}
                </template>
                <template #moduleType="{ scope }">
                  {{ scope.record.name }}
                </template>
              </XATable>
            </div>
          </div></a-col
        >
      </a-row>
    </div>
    <!-- 下半部分 -->
    <div style="margin-top: 20px">
      <a-row :gutter="30" class="a-row-content">
        <a-col :span="12">
          <a-divider orientation="left">新用户数据</a-divider>
          <XATable
            :columns="userListColumns"
            :dataSource="userList"
            :scroll="{ y: bottomHeight }"
            size="small"
            :height="bottomHeight + 60 + 'px'"
            :loading="newUserLoading"
          >
          </XATable>
        </a-col>
        <a-col :span="12">
          <a-divider orientation="left">阅读/观看数据</a-divider>
          <div class="read-title">今日</div>
          <XATable
            :columns="historyToDayColumns"
            :dataSource="historyToDayList"
            :scroll="{ y: bottomHeight / 2 - 10 - 50 }"
            size="small"
            :height="(bottomHeight - 10) / 2 + 'px'"
            :loading="readLookLoading"
          >
          </XATable>
          <div class="read-title">昨日</div>
          <XATable
            :columns="historyYesToDayColumns"
            :dataSource="historyYesToDayList"
            :scroll="{ y: bottomHeight / 2 - 10 - 50 }"
            size="small"
            :height="(bottomHeight - 10) / 2 + 'px'"
            :loading="readLookLoading"
          >
          </XATable>
        </a-col>
      </a-row>
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ordersList,
  newUserList,
  historyToDay,
  historyYesToDay,
  orderInTime,
  orderInTimeList,
} from "@/api/api";
import { ts_to_time } from "@/utils/utils";
import {
  ref,
  defineAsyncComponent,
  onMounted,
  watch,
  reactive,
  nextTick,
  onBeforeUnmount,
} from "vue";
const XATable = defineAsyncComponent(() => import("../components/XATable.vue"));
import { onBeforeRouteLeave } from "vue-router";

const props = defineProps<{
  appData: any;
  //   dateTime: any;
  //   isConsume: boolean;
  isRefresh: boolean;
  dbName: String;
}>();

const normalColumns = ref([
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    align: "center",
    customRender: (column: { index: number }) => {
      //   return (pageNo.value - 1) * pageSize.value + column.index + 1;
      return column.index + 1;
    },
    width: 50,
  },
  {
    title: "书名/剧名",
    key: "name",
    dataIndex: "name",
    ellipsis: true,
    width: 140,
  },
  {
    title: "订单",
    key: "orders",
    dataIndex: "orders",
    slots: {
      customRender: "orders",
    },
  },
  {
    title: "汇总",
    key: "amount",
    dataIndex: "amount",
    slots: {
      customRender: "amount",
    },
  },
]);
const vipColumns = ref([
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    align: "center",
    customRender: (column: { index: number }) => {
      //   return (pageNo.value - 1) * pageSize.value + column.index + 1;
      return column.index + 1;
    },
    width: 50,
  },
  {
    title: "价格",
    key: "price",
    dataIndex: "price",
    slots: {
      customRender: "price",
    },
  },
  {
    title: "订单",
    key: "orders",
    dataIndex: "orders",
    slots: {
      customRender: "orders",
    },
  },
  {
    title: "汇总",
    key: "amount",
    dataIndex: "amount",
    slots: {
      customRender: "amount",
    },
  },
]);
const svipColumns = ref([
  {
    title: "#",
    dataIndex: "index",
    key: "index",
    align: "center",
    customRender: (column: { index: number }) => {
      //   return (pageNo.value - 1) * pageSize.value + column.index + 1;
      return column.index + 1;
    },
    width: 50,
  },
  {
    title: "时间",
    key: "time",
    dataIndex: "time",
  },
  {
    title: "订单",
    key: "orders",
    dataIndex: "orders",
    slots: {
      customRender: "orders",
    },
  },
  {
    title: "汇总",
    key: "amount",
    dataIndex: "amount",
    slots: {
      customRender: "amount",
    },
  },
]);
const userListColumns = ref([
  {
    title: "注册时间",
    key: "regTime",
    dataIndex: "regTime",
    isAllTime: true,
  },
  {
    title: "地区",
    key: "ipName",
    dataIndex: "ipName",
  },
  {
    title: "用户名",
    key: "userName",
    dataIndex: "userName",
  },
  //   {
  //     title: "UID",
  //     key: "time",
  //     dataIndex: "time",
  //   },
  {
    title: "类型",
    key: "time",
    dataIndex: "time",
    customRender: (column: { index: number }) => {
      return "短剧";
    },
  },
  {
    title: "书名/剧名",
    key: "videoName",
    dataIndex: "videoName",
  },
  {
    title: "最后阅读记录",
    key: "chapterTitle",
    dataIndex: "chapterTitle",
  },
  {
    title: "最后阅读时间",
    key: "readTime",
    dataIndex: "readTime",
    isAllTime: true,
  },
  {
    title: "订单数",
    key: "orders",
    dataIndex: "orders",
  },
  {
    title: "书币",
    key: "coins",
    dataIndex: "coins",
  },
]);
const historyToDayColumns = ref([
  {
    title: "平台",
    key: "packageName",
    dataIndex: "packageName",
  },
  {
    title: "阅读用户",
    key: "readUserCount",
    dataIndex: "readUserCount",
  },
  {
    title: "阅读记录",
    key: "readTotalCount",
    dataIndex: "readTotalCount",
  },
  {
    title: "观看用户",
    key: "lookUserCount",
    dataIndex: "lookUserCount",
  },
  {
    title: "观看记录",
    key: "lookTotalCount",
    dataIndex: "lookTotalCount",
  },
  {
    title: "书币消耗",
    key: "coins",
    dataIndex: "coins",
  },
]);
const historyYesToDayColumns = ref([
  {
    title: "平台",
    key: "packageName",
    dataIndex: "packageName",
  },
  {
    title: "阅读用户",
    key: "readUserCount",
    dataIndex: "readUserCount",
  },
  {
    title: "阅读记录",
    key: "readTotalCount",
    dataIndex: "readTotalCount",
  },
  {
    title: "观看用户",
    key: "lookUserCount",
    dataIndex: "lookUserCount",
  },
  {
    title: "观看记录",
    key: "lookTotalCount",
    dataIndex: "lookTotalCount",
  },
  {
    title: "书币消耗",
    key: "coins",
    dataIndex: "coins",
  },
]);
const newOrderColumns = ref([
  {
    title: "平台",
    key: "packageName",
    dataIndex: "packageName",
  },
  {
    title: "订单时间",
    key: "orderTime",
    dataIndex: "orderTime",
    isAllTime: true,
    width: 160,
  },
  {
    title: "订单信息",
    key: "amount",
    dataIndex: "amount",
    slots: {
      customRender: "amount",
    },
  },
  {
    title: "用户",
    key: "userName",
    dataIndex: "userName",
  },
  {
    title: "地区",
    key: "ipName",
    dataIndex: "ipName",
  },
  {
    title: "总充值",
    key: "userAmountTotal",
    dataIndex: "userAmountTotal",
    slots: {
      customRender: "userAmountTotal",
    },
  },
  {
    title: "剧名",
    key: "moduleType",
    dataIndex: "moduleType",
    slots: {
      customRender: "moduleType",
    },
  },
]);
const normalData = reactive({ top: {}, bottom: [] } as any);
const vipData = reactive({ top: {}, bottom: [] } as any);
const svipData = reactive({ top: {}, bottom: [] } as any);
const bottomHeight = ref();
const userList = ref([]);
const historyToDayList = ref([]);
const historyYesToDayList = ref([]);
const newUserInfos = ref([]);
const newUserInfo = ref({} as any);
const checkedValue = ref<boolean>(true);
const newOrderTimer = ref();
var voicedOrderNos: any[] = [];
const orderAllLoading = ref(false);
const newUserLoading = ref(false);
const readLookLoading = ref(false);
const newOrderLoading = ref(false);
var appIdList: any[] = [];
const appIdVal = ref();
const rightAppIdVal = ref<any[]>([]);
const maxTagCount = ref(1);
const allOrderTimer = ref();
const newUserListTimer = ref();

const getOrderList = () => {
  orderAllLoading.value = true;
  ordersList({ dbName: appIdVal.value }).then((res) => {
    orderAllLoading.value = false;
    res.data[0][0].subs.forEach((item: any) => {
      if (item.type === 1) {
        normalData.top = item;
      } else if (item.type === 2) {
        vipData.top = item;
      } else if (item.type === 3) {
        svipData.top = item;
      }
    });

    normalData.bottom = res.data[1];
    vipData.bottom = res.data[2];
    svipData.bottom = res.data[3];
  });
};

const getNewUserList = () => {
  newUserLoading.value = true;
  newUserList({ dbName: appIdVal.value }).then((res) => {
    newUserLoading.value = false;
    userList.value = res.data;
  });
};

const getHistoryToDay = () => {
  readLookLoading.value = true;
  historyToDay({
    dbName: decodeURIComponent(rightAppIdVal.value.join(",")),
  })
    .then((res) => {
      readLookLoading.value = false;
      historyToDayList.value = res.data;
    })
    .catch(() => {
      readLookLoading.value = false;
    });
};

const getHistoryYesToDay = () => {
  readLookLoading.value = true;
  historyYesToDay({
    dbName: decodeURIComponent(rightAppIdVal.value.join(",")),
  })
    .then((res) => {
      readLookLoading.value = false;
      historyYesToDayList.value = res.data;
    })
    .catch(() => {
      readLookLoading.value = false;
    });
};

const startVoiceTimer = () => {
  stopVoiceTimer();
  newOrderTimer.value = setInterval(() => {
    getOrderInTime();
  }, 2000);
};

const stopVoiceTimer = () => {
  if (newOrderTimer.value) {
    clearInterval(newOrderTimer.value);
    newOrderTimer.value = null;
  }
};

const getOrderInTime = () => {
  stopVoiceTimer();
  newOrderLoading.value = true;
  orderInTime({ dbName: decodeURIComponent(rightAppIdVal.value.join(",")) })
    .then((res) => {
      newOrderLoading.value = false;
      startVoiceTimer();
      if (res.data.length > 0) {
        newUserInfo.value = res.data[0];
        voicedOrderNos = JSON.parse(
          localStorage.getItem("voicedOrderNos") as string
        )
          ? JSON.parse(localStorage.getItem("voicedOrderNos") as string)
          : [];
        if (!voicedOrderNos.includes(newUserInfo.value.orderNo)) {
          if (checkedValue.value) {
            var voiceStr: string =
              newUserInfo.value.packageName +
              "有新订单，充值金额为" +
              (newUserInfo.value.amount / 100).toFixed(2) +
              (newUserInfo.value.name
                ? "，剧名为：" + newUserInfo.value.name
                : "，没有剧名");
            speak(voiceStr);
          }
          voicedOrderNos.push(newUserInfo.value.orderNo);
          localStorage.setItem(
            "voicedOrderNos",
            JSON.stringify(voicedOrderNos)
          );
          getOrderInTimeList();
          // newUserInfos.value.push(newUserInfo.value)
          // localStorage.setItem("MONITOR",newUserInfo.value)
        }
      }
    })
    .catch(() => {
      startVoiceTimer();
    });
};

// const getMoniTorData = () => {

//   newUserInfos.value = JSON.parse(localStorage.getItem("MONITOR") as string)

// }

const getOrderInTimeList = () => {
  orderInTimeList({
    dbName: `${decodeURIComponent(rightAppIdVal.value.join(","))}`,
  }).then((res) => {
    newUserInfos.value = res.data;
  });
};

const getAllOrderTimer = () => {
  getOrderList();
  if (rightAppIdVal.value.length) {
    getHistoryToDay();
    getHistoryYesToDay();
  }

  clearInterval(allOrderTimer.value);
  allOrderTimer.value = null;
  allOrderTimer.value = setInterval(() => {
    getOrderList();
    if (rightAppIdVal.value.length) {
      getHistoryToDay();
      getHistoryYesToDay();
    }
  }, 1000 * 120);
};

const getNewUserListTimer = () => {
  getNewUserList();

  clearInterval(newUserListTimer.value);
  newUserListTimer.value = null;
  newUserListTimer.value = setInterval(() => {
    getNewUserList();
  }, 1000 * 600);
};
const stopAllTimer = () => {
  stopVoiceTimer();
  clearInterval(allOrderTimer.value);
  allOrderTimer.value = null;
  clearInterval(newUserListTimer.value);
  newUserListTimer.value = null;
};
watch(
  () => props.appData,
  (cur, old) => {
    if (cur && cur.length) {
      appIdList = [];
      cur.forEach((val: { dbName: any }) => {
        appIdList.push(val.dbName);
      });
      if (localStorage.getItem("APPIDLIST")) {
        rightAppIdVal.value = JSON.parse(
          localStorage.getItem("APPIDLIST") as string
        );
      } else {
        rightAppIdVal.value = appIdList;
      }
      if (localStorage.getItem("APPID")) {
        appIdVal.value = localStorage.getItem("APPID");
      } else {
        appIdVal.value = cur[0].dbName;
      }

      getNewUserListTimer();
      getAllOrderTimer();

      if (rightAppIdVal.value.length) {
        newUserInfos.value = [];
        newUserInfo.value = {};
        getOrderInTime();
        getOrderInTimeList();
      }
    }
  },
  { immediate: true, deep: true }
);

watch(
  () => props.isRefresh,
  (cur, old) => {
    if (appIdVal.value) {
      getNewUserListTimer();
      getAllOrderTimer();
      newUserInfos.value = [];
      newUserInfo.value = {};
      getOrderInTime();
    }
  }
);

const rightAppIdValChange = (val: any[]) => {
  localStorage.setItem("APPIDLIST", JSON.stringify(val));
  if (val.length) {
    startVoiceTimer();
  } else {
    stopVoiceTimer();
  }
};

const appIdChange = (val: string) => {
  localStorage.setItem("APPID", val);
  getOrderList();
  getNewUserList();
};

const getBottomHeight = () => {
  if (window.innerHeight < 1150) {
    return;
  }
  bottomHeight.value = window.innerHeight - 800;
};

const voiceChange = (val: any) => {
  localStorage.setItem("VOICE", val);
};

const speak = (textStr: string) => {
  // 获取输入框中的文本
  const text = textStr;
  // 创建一个新的SpeechSynthesisUtterance对象
  const utterance = new SpeechSynthesisUtterance(text);
  // 设置语音属性（可选）
  utterance.lang = "zh-CN"; // 设置语言为中文
  utterance.pitch = 1; // 设置音调
  utterance.rate = 1; // 设置语速
  utterance.volume = 1; // 设置音量
  // 使用speechSynthesis对象播报文本
  window.speechSynthesis.speak(utterance);
};
const isOpenVoice = () => {
  if (localStorage.getItem("VOICE") === "true") {
    checkedValue.value = true;
  } else if (localStorage.getItem("VOICE") === "false") {
    checkedValue.value = false;
  } else {
    checkedValue.value = true;
  }
};
onMounted(() => {
  isOpenVoice();
  getBottomHeight();
  window.addEventListener("resize", function () {
    // 获取浏览器窗口的新高度
    const windowHeight = window.innerHeight;

    // 在这里处理窗口高度变化的逻辑，比如更新数据或触发其他操作
    getBottomHeight();
  });

  nextTick(() => {
    isOpenVoice();
  });
});

onBeforeUnmount(() => {
  console.log("离开");
  stopAllTimer();
});
</script>

<style lang="less" scoped>
.content {
  background-color: #f5f5f5;
  width: 100%;
  height: auto;
  border-radius: 6px;
  padding: 10px;
  //   min-height: 900px;
  min-width: 1900px;

  // .ant-tabs-tabpane {
  //   overflow-x: auto !important;
  // }
  // .ant-row {
  //   margin-left: -10px !important;
  //   margin-right: -10px !important;
  // }

  .a-row-content {
    margin-left: -10px !important;
    margin-right: -10px !important;
  }
}

.data-top {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50%;
  .data-top-bottom {
    margin-top: 20px;
  }
  .order-all {
    width: 50%;
    height: 100%;
    padding: 5px;
  }
  .new-order {
    width: 50%;
    height: 100%;
    padding: 5px;
  }
  .finish-info {
    display: flex;
    justify-content: space-around;
  }
}
.read-title {
  padding: 5px;
  font-size: 16px;
}
.from-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right-select {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: flex-end;
}
</style>

<style>
.ant-divider-inner-text {
  font-size: 20px;
}
.ant-descriptions-title {
  font-size: 14px !important;
}
.ant-descriptions-header {
  margin-bottom: 7px !important;
}
</style>
