<template>
  <a-config-provider :locale="locale">
    <router-view></router-view
  ></a-config-provider>
</template>

<script lang="ts" setup>
import {
  defineAsyncComponent,
  defineComponent,
  ref,
  onMounted,
  onBeforeUnmount,
} from "vue";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");
const locale = ref(zhCN);
import router from "./router";
import { useStore } from "vuex";
const store = useStore();
// import DataCenter from "./views/DataCenter.vue"
const DataCenter = defineAsyncComponent(() => import("./views/DataCenter.vue"));
// export default defineComponent({
//   components:{
//     DataCenter
//   },
//   setup() {

//     return {};
//   },
// });
const timerInval = ref();
const startSubTimer = () => {
  clearInterval(timerInval.value);
  timerInval.value = null;
  timerInval.value = setInterval(() => {
    store.dispatch("getSubOrderWatch");
  }, 2000);
};

const refundTimerInval = ref();
const startRefundTimer = () => {
  clearInterval(refundTimerInval.value);
  refundTimerInval.value = null;
  refundTimerInval.value = setInterval(() => {
    store.dispatch("getRefundOrderWatch");
  }, 2000);
};

onMounted(() => {
  if (!localStorage.getItem("REFUNDNotifNO")) {
    store.dispatch("changeRefundCheckedValue", true);
  }
  if (!localStorage.getItem("NotifNO")) {
    store.dispatch("changeSubCheckedValue", true);
  }
  startSubTimer();
  startRefundTimer();
});
onBeforeUnmount(() => {
  stopAllTimer();
});
const stopAllTimer = () => {
  clearInterval(timerInval.value);
  timerInval.value = null;
  clearInterval(refundTimerInval.value);
  refundTimerInval.value = null;
};
router.beforeEach((to, from, next) => {
  //退出到登录的时候停止监听
  if (to.path == "/login") {
    stopAllTimer();
  } else {
    startSubTimer();
    startRefundTimer();
  }
  next();
});
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
html {
  width: 100% !important;
  /* height: 100% !important; */
}
</style>
