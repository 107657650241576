<template>
  <a-modal
    v-model:visible="diaVisible"
    :title="ctitle"
    @ok="handleOk"
    @cancel="cancel"
  >
    <slot></slot>
    <template #footer>
      <span class="dialog-footer">
        <a-button @click="cancel">取消</a-button>
        <a-button type="primary" @click="handleOk" :loading="xloading">
          确定
        </a-button>
      </span>
    </template>
  </a-modal>
</template>

<script>
import { onMounted, ref, watch } from "vue";
export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "提示",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, ctx) {
    const diaVisible = ref();
    const ctitle = ref("提示");
    const xloading = ref(false);
    watch(
      () => props.visible,
      (val) => {
        diaVisible.value = val;
      }
    );
    watch(
      () => props.title,
      (val) => {
        ctitle.value = val;
      },
      { immediate: true }
    );
    watch(
      () => props.loading,
      (val) => {
        xloading.value = val;
      },
      { immediate: true }
    );
    onMounted(() => {});
    const handleOk = () => {
      ctx.emit("confirm");
    };
    const cancel = () => {
      diaVisible.value = false;
      ctx.emit("cancel");
    };
    return {
      diaVisible,
      ctitle,
      xloading,
      handleOk,
      cancel,
    };
  },
};
</script>

<style></style>
