<template>
  <div class="nav">
    <!-- :style="{ height: modeChangeKey === 'card'? '60px !important' : '90px !important'}" -->
    <div></div>
    <div class="header">
      <!-- <div style="margin-right:3px;font-weight: 600;margin-top:5px">
        {{ isCardMode?"管理员":"用户" }}
      </div> -->
      <a-dropdown :trigger="['click']" class="all">
        <template #overlay>
          <a-menu>
            <a-menu-item key="1" @click="editPassword"> 修改密码 </a-menu-item>
            <a-menu-item key="2" @click="loginOutClick"> 退出登录 </a-menu-item>
          </a-menu>
        </template>
        <a-button>
          <template #icon><MenuOutlined /></template>
        </a-button>
      </a-dropdown>
    </div>
  </div>
  <div class="top-data">
    <div>
      BM账户个数：<span>{{
        countData.businessNumber ? countData.businessNumber : 0
      }}</span>
    </div>
    <div>
      广告账户数：<span>{{
        countData.accountNumber ? countData.accountNumber : 0
      }}</span>
    </div>
    <div>
      累计余额($)：<span>{{
        countData.amountsUsd ? countData.amountsUsd : 0
      }}</span>
    </div>
    <div>
      累计余额(₦)：<span>{{
        countData.amountsNGN ? countData.amountsNGN : 0
      }}</span>
    </div>
    <div>
      使用中：<span>{{ countData.status1 ? countData.status1 : 0 }}</span>
    </div>
    <div>
      已停用：<span>{{ countData.status2 ? countData.status2 : 0 }}</span>
    </div>
    <!-- <div>
      已欠费：<span>{{ countData.status3 ? countData.status3 : 0 }}</span>
    </div> -->
    <div>
      已关闭：<span>{{ countData.status101 ? countData.status101 : 0 }}</span>
    </div>
  </div>
  <XATable
    :columns="columns"
    :dataSource="tableData"
    :scroll="{ x: 1500 }"
    :loading="loading"
    @myChange="onHandleChange"
  >
    <template #accountId="{ scope }">
      {{ scope.record.accountId }} <br />
      {{ scope.record.accountName }}
    </template>
    <template #updateTime="{ scope }">
      {{ scope.record.updateTime }}
    </template>
    <template #businessId="{ scope }">
      {{ scope.record.businessId }}<br />
      {{ scope.record.businessName }}
    </template>
    <template #amountCap="{ scope }">
      {{ scope.record.amountCap }}
    </template>
    <template #accountStatus="{ scope }">
      <a-tag color="green" v-if="scope.record.accountStatus === 1"
        >使用中</a-tag
      >
      <a-tag color="red" v-if="scope.record.accountStatus === 2">已停用</a-tag>
      <a-tag color="orange" v-if="scope.record.accountStatus === 3"
        >已欠费</a-tag
      >
      <a-tag color="" v-if="scope.record.accountStatus === 101">已关闭</a-tag>
    </template>
  </XATable>
  <a-pagination
    v-model:current="pageNo"
    v-model:pageSize="pageSize"
    @change="pageNoChange"
    :total="total"
    show-size-changer
    @showSizeChange="onShowSizeChange"
    style="margin-top: 5px; padding-bottom: 10px; margin-left: 10px"
  />
  <x-dialog
    title="修改密码"
    :visible="dialogVisible"
    @confirm="confirm"
    @cancel="dialogVisible = false"
  >
    <a-form
      :model="formState"
      name="basic"
      :label-col="{ span: 6 }"
      :wrapper-col="{ span: 18 }"
      autocomplete="off"
    >
      <a-form-item
        label="用户名"
        name="account"
        :rules="[{ required: false, message: '请输入用户名!' }]"
      >
        <a-input disabled v-model:value="formState.account" />
      </a-form-item>
      <a-form-item
        label="旧密码"
        name="oldPassword"
        :rules="[{ required: true, message: '请输入旧密码!' }]"
      >
        <a-input-password v-model:value="formState.oldPassword" />
      </a-form-item>

      <a-form-item
        label="新密码"
        name="password"
        :rules="[{ required: true, message: '请输入新密码!' }]"
      >
        <a-input-password v-model:value="formState.password" />
      </a-form-item>

      <!-- <a-form-item :wrapper-col="{ offset: 8, span: 16 }">
        <a-button type="primary" html-type="submit">Submit</a-button>
      </a-form-item> -->
    </a-form>
  </x-dialog>
</template>
<script setup lang="ts">
import { MenuOutlined } from "@ant-design/icons-vue";
import {
  businessPage,
  loginOut,
  resetPassword,
  businessCount,
} from "@/api/api";
import { defineAsyncComponent, onMounted, reactive, ref } from "vue";
import { useRouter } from "vue-router";
import XDialog from "@/components/XDialog.vue";
const XATable = defineAsyncComponent(() => import("../components/XATable.vue"));
import { removeToken } from "@/utils/auth";

interface FormState {
  account: string;
  password: string;
  oldPassword: string;
}

const formState = ref<FormState>({
  account: "sys",
  password: "",
  oldPassword: "",
});
const router = useRouter();
const tableData = ref([] as any[]);
const total = ref(0);
const dialogVisible = ref();
const columns = ref([
  {
    title: "更新时间",
    key: "update_time",
    dataIndex: "update_time",
    sorter: true,
    slots: {
      customRender: "updateTime",
    },
  },
  {
    title: "BM",
    key: "business_id",
    dataIndex: "business_id",
    slots: {
      customRender: "businessId",
    },
    sorter: true,
  },
  {
    title: "广告账号",
    key: "accountId",
    dataIndex: "accountId",
    slots: {
      customRender: "accountId",
    },
  },
  {
    title: "单日花费限额",
    key: "amount_cap",
    dataIndex: "amount_cap",
    slots: {
      customRender: "amountCap",
    },
    sorter: true,
  },
  {
    title: "余额",
    key: "amount",
    dataIndex: "amount",
    sorter: true,
  },
  {
    title: "状态",
    key: "account_status",
    dataIndex: "account_status",
    slots: {
      customRender: "accountStatus",
    },
    sorter: true,
  },
]);

const pageNo = ref(1);
const pageSize = ref(1000);
const loading = ref(false);
const ascField = ref("amount");
const ascOrder = ref("asc");
const countData: any = ref({});

onMounted(() => {
  getCountData();
  getDatas();
});

const pageNoChange = (val: number) => {
  pageNo.value = val;
  getDatas();
};
const onShowSizeChange = (current: any, size: number) => {
  pageSize.value = size;
  getDatas();
};

const getDatas = () => {
  const params = {
    pageNo: pageNo.value,
    pageSize: pageSize.value,
    field: ascField.value,
    order: ascOrder.value,
  };
  loading.value = true;
  businessPage(params).then((res) => {
    loading.value = false;
    tableData.value = res.data.records;
  });
};
const editPassword = () => {
  dialogVisible.value = true;
};
const loginOutClick = () => {
  loginOut({}).then((res) => {
    router.replace("/");
    removeToken();
  });
};
const confirm = () => {
  resetPassword(formState.value).then((res) => {
    if (res.code === "200") {
      loginOutClick();
    }
  });
};
const onHandleChange = (params: any) => {
  console.log(params.pagination, params.filters, params.sorter, params.extra);
  ascField.value = params.sorter.field;
  ascOrder.value =
    params.sorter.order === "ascend"
      ? "asc"
      : params.sorter.order === "descend"
      ? "desc"
      : "";
  getDatas();
};
const getCountData = () => {
  businessCount({}).then((res) => {
    console.log(res);
    countData.value = res.data;
  });
};
</script>
<style lang="less" scoped>
.nav {
  height: 60px;
  padding: 10px;
  box-sizing: border-box;
  background: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.header {
  display: flex;
  align-items: center;
}
.top-data {
  padding: 10px 10px;
  // display: flex;
  font-size: 16px;
  div {
    float: left;
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;
    span {
      color: orange;
    }
  }
}
@media screen and (max-width: 430px) {
  .top-data {
    div {
      min-width: 120px;
    }
  }
}
</style>
