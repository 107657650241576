export function ts_to_time(timestamp: string | number | Date) {
  if (typeof timestamp === "string") {
    timestamp = Number(timestamp);
  }
  if (typeof timestamp !== "number") {
    alert("输入参数无法识别为时间戳");
  }
  let date = new Date(timestamp);
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = date.getDate() + " ";
  let h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  let m =
    date.getMinutes() < 10
      ? "0" + date.getMinutes() + ":"
      : date.getMinutes() + ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}

export function ts_to_time2(timestamp: string | number | Date) {
  if (typeof timestamp === "string") {
    timestamp = Number(timestamp);
  }
  if (typeof timestamp !== "number") {
    alert("输入参数无法识别为时间戳");
  }
  let date = new Date(timestamp);
  let Y = date.getFullYear() + "-";
  let M =
    (date.getMonth() + 1 < 10
      ? "0" + (date.getMonth() + 1)
      : date.getMonth() + 1) + "-";
  let D = date.getDate() + " ";
  let h =
    date.getHours() < 10 ? "0" + date.getHours() + ":" : date.getHours() + ":";
  let m =
    date.getMinutes() < 10
      ? "0" + date.getMinutes() + ":"
      : date.getMinutes() + ":";
  let s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return m + s;
}

export function formatDate(timestamp: number, format: string = 'YYYY-MM-DD hh:mm:ss'): string {
    const date = new Date(timestamp * 1000);

    const o: { [key: string]: number } = {
        "M+": date.getMonth() + 1,                 // 月份
        "D+": date.getDate(),                      // 日
        "h+": date.getHours(),                     // 小时
        "m+": date.getMinutes(),                   // 分
        "s+": date.getSeconds(),                   // 秒
        "q+": Math.floor((date.getMonth() + 3) / 3), // 季度
        "S": date.getMilliseconds()                // 毫秒
    };

    if (/(Y+)/.test(format)) {
        format = format.replace(RegExp.$1, (date.getFullYear() + '').slice(4 - RegExp.$1.length));
    }

    for (let k in o) {
        if (new RegExp("(" + k + ")").test(format)) {
            format = format.replace(RegExp.$1, RegExp.$1.length === 1 ? String(o[k]) : ("00" + o[k]).slice(String(o[k]).length));
        }
    }

    return format;
}
