<template>
  <div class="login-content">
    <div class="login-box">
      <div
        style="
          text-align: center;
          margin-bottom: 40px;
          font-size: 28px;
          color: #fff;
        "
      >
        短剧数据管理平台
      </div>
      <a-form
        :model="formState"
        name="basic"
        :label-col="{ span: 6 }"
        autocomplete="off"
        @finish="onFinish"
        @finishFailed="onFinishFailed"
      >
        <a-form-item
          name="account"
          :rules="[{ required: true, message: '请输入用户名!' }]"
        >
          <a-input
            class="input"
            v-model:value="formState.account"
            style="color: #fff"
          >
            <template #prefix>
              <User type="user" style="color: rgb(117, 136, 146)" />
            </template>
          </a-input>
        </a-form-item>

        <a-form-item
          name="password"
          :rules="[{ required: true, message: '请输入密码!' }]"
        >
          <a-input-password class="input" v-model:value="formState.password">
            <template #prefix>
              <Lock type="user" style="color: rgb(117, 136, 146)" />
            </template>
          </a-input-password>
        </a-form-item>

        <!-- <a-form-item name="remember" :wrapper-col="{ offset: 8, span: 16 }">
          <a-checkbox v-model:checked="formState.remember"
            >Remember me</a-checkbox
          >
        </a-form-item> -->
        <a-form-item>
          <a-button
            type="primary"
            html-type="submit"
            :loading="loading"
            style="width: 100%"
            >登录</a-button
          >
        </a-form-item>
      </a-form>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref } from "vue";
import { login } from "@/api/api";
import { setToken } from "@/utils/auth";
import { useRouter } from "vue-router";
import { UserOutlined, LockOutlined } from "@ant-design/icons-vue";
import User from "@/components/svg/User.vue";
import Lock from "@/components/svg/lock.vue";

interface FormState {
  account: string;
  password: string;
}

export default defineComponent({
  components: {
    UserOutlined,
    LockOutlined,
    User,
    Lock,
  },
  setup() {
    const router = useRouter();
    const loading = ref(false);
    const formState = reactive<FormState>({
      account: "",
      password: "",
    });
    const onFinish = (values: any) => {
      // console.log("Success:", values);

      loading.value = true;
      login(values).then(
        (res: { code: string; data: { token: any; name: string } }) => {
          // console.log(res);
          loading.value = false;
          if (res.code === "200") {
            setToken(res.data.token);
            localStorage.setItem("mode", res.data.name);
            if (res.data.name === "sys") {
              router.push("/accounts");
            } else {
              router.push("/home");
            }
          }
        }
      );
    };

    const onFinishFailed = (errorInfo: any) => {
      console.log("Failed:", errorInfo);
    };
    return {
      formState,
      loading,
      onFinish,
      onFinishFailed,
    };
  },
});
</script>
<style lang="less" scoped>
.input {
  background-color: rgb(30, 39, 52) !important;
  border: 1px solid rgb(47, 56, 71) !important;
  padding: 10px 11px !important;
  border-radius: 4px;
  &:deep(.ant-input) {
    background: rgb(30, 39, 52);
    color: #fff;
  }
  &:deep(.anticon svg) {
    color: rgb(117, 136, 146) !important;
  }
  &:deep(.ant-input-affix-wrapper) {
    background-color: rgb(30, 39, 52) !important;
  }
}
.login-content {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(33, 45, 60);
  color: #fff;
}
.login-box {
  width: 320px;
  /* text-align: center; */
}
</style>
<style>
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper:hover {
  background-color: none !important;
}
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper,
.ant-form-item-has-error
  :not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-form-item-has-error
  :not(.ant-input-affix-wrapper-disabled):not(
    .ant-input-affix-wrapper-borderless
  ).ant-input-affix-wrapper:hover,
.ant-form-item-has-error
  :not(.ant-input-number-affix-wrapper-disabled):not(
    .ant-input-number-affix-wrapper-borderless
  ).ant-input-number-affix-wrapper:hover {
  background-color: unset !important;
}
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff;
}
</style>
